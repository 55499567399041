/* @import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap'); */


/* @import url('https://fonts.googleapis.com/css2?family=Lateef&display=swap'); */

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Lateef&display=swap');
body {
    /*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;*/
    /* font-family: 'Nunito Sans', sans-serif !important; */
    font-family: 'Inter', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@media (max-width: 1000px) {
    body {
        margin-top: 50px;
        align-items: center;
        justify-content: center;
        text-align: center;
    }
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.essay-link {
    color: black;
    text-decoration: none;
}

.essay-link:hover {
    text-decoration: underline;
}

.essay-div {
    text-align: start !important;
}

#poetry {
    text-align: start !important;
}

.highlight {
    background: #9191f747;
}

h1::selection,
h2::selection,
h6::selection,
h4::selection,
h3::selection,
h5::selection,
p::selection,
span::selection,
em::selection,
li::selection,
ul::selection,
nav::selection,
a:hover,
a::selection,
br::selection,
strong::selection,
i::selection,
img::selection {
    background: #9191f747;
}

.work-list li {
    line-height: 150%;
}

.link-img {
    height: 18px;
    margin-bottom: 3px;
    margin-left: -3px;
    padding-left: 4px;
}